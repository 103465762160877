import React, {Component} from 'react';
import {Route, Link} from 'react-router-dom';
import $ from 'jquery/dist/jquery.js';
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import {ToastsContainer, ToastsStore, ToastsContainerPosition, timer} from 'react-toasts';
import Nav from './nav-dashboard-skin';
import atclogo from '../images/loader.png'
import coverKids from '../images/cover-kids.png';
import web from '../images/web.png';
import webpage from '../images/webpage.png';
import website from '../images/web-pages.png';
import editor from '../images/text-editor.png';
import html from '../images/html.png';
import quiz from '../images/quiz-icon.png';
import forwardSwitchButton from '../images/next.png';
import switchButtonDisabled from '../images/next-disabled.png';
import backwardSwitchButtonDisabled from '../images/back-disabled.png';
import SwitchButton from './switch-button';
import BackwardSwitchButton from './backward-switch-button';
import TheWeb from './learningmodules/theweb';
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderA from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import vle from '../images/vle.png'
import navigation from '../images/nav.png'
import attendance from '../images/attendance.png'
import fellows from '../images/fellows.png'
import skins from '../images/skins.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCaretRight, faSearch, faUserGear } from '@fortawesome/free-solid-svg-icons'





 class lab extends Component { 

   constructor(props) { 
    super(props);

     this.state ={ 
        height:"",
        width:"",
        skinPack:[],
        iconPack:[],
        assessmentRef:[],
        assessmentPretty:[],
        prevResourceRef:"",
        prevTopicRef:"",
        resoureceRef: "",
        topicRef:"",
        topicStatus:false,
        candidateFirstName:"",
        codeData:"",
        taskComplete:"",
        quizAnswer:"",
        userAnswer:"",
        taskByteValue:"",
       candidateSkin:"",
       candidateSkinRef:"",
       candidateAvatar:"",
       candidateAssessmentStatus:"",
       candidateClass:"",
       candidateAge:"",
       candidateSchool:"",
       candidateFullname: "",
       candidateSchoolid:"",
      candidateTopicVisual: "",
      candidateTopicDescription: "",
      candidateTopicName: "", 
       onlineCandidates:[],
       currentAssessmentTaskRef: "",
       currentAssessmentTopicRef: "",
       currentAssessmentRef: "",
       assessmentLink:"",
       schoollogo:"",
       assessmentGrade:"",
       totalTopicGrade:"",
       currentTopicGrade:"",
       newAssessmentRef:"",
        addManualModalShow: false,
        resetTopicName : "",
        resetVisual : "",
        resetTopicRef : "",
        resetResourceRef: "",
        resetModal: false, 
        booksPack:[],
        booksDetailsPack:"", 
        reviewBookName:"",
        reviewBookCover:"",
        bookTopics:[],
        notActiveModal:false,
        backgroundImage:"https://res.cloudinary.com/www-code4teen-tech/image/upload/v1690964489/Andrew_egre5k.png",
        themecolorA:23,
        themecolorB:144,
        themecolorC:247
        
     }; 

 

 
     this.canvasHeight = this.canvasHeight.bind(this);
     this.canvasWidth = this.canvasWidth.bind(this);
     //this.timeUpdate = this.timeUpdate.bind(this);
     //this.sleepScreen = this.sleepScreen.bind(this);
     this.menuScreen = this.menuScreen.bind(this);
     this.menuClose = this.menuClose.bind(this);
     this.profileScreen = this.profileScreen.bind(this);
     this.exploreScreen = this.exploreScreen.bind(this);
     this.exploreClose = this.exploreClose.bind(this);
     this.profileClose = this.profileClose.bind(this);
    // this.wakeScreen = this.wakeScreen.bind(this);
     this.skinMenu = this.skinMenu.bind(this);
     this.profileMenu = this.profileMenu.bind(this);
     this.skinUpdate = this.skinUpdate.bind(this);
     this.byteStateUpdate = this.byteStateUpdate.bind(this);
     this.candidateByteIncrease = this.candidateByteIncrease.bind(this);
     this.candidateByteDecrease = this.candidateByteDecrease.bind(this);
     this.retrieveResourceDetails = this.retrieveResourceDetails.bind(this);
     this.switchSkin = this.switchSkin.bind(this);
     this.validateResponse = this.validateResponse.bind(this);
     this.eventValidateResponse = this.eventValidateResponse.bind(this);
     this.htmlStateUpdate = this.htmlStateUpdate.bind(this);
     this.progressUpdate = this.progressUpdate.bind(this);
     this.taskStatusUpdate = this.taskStatusUpdate.bind(this);
     this.quizAnswerUpdate = this.quizAnswerUpdate.bind(this);
     this.validateQuizResponse = this.validateQuizResponse.bind(this);
     this.quizResponseUpdate = this.quizResponseUpdate.bind(this);
     this.retreiveResourceIconPack = this.retreiveResourceIconPack.bind(this);
     this.saveEditorResponse = this.saveEditorResponse.bind(this);
     this.retrieveOnlineFellowsBySchool = this.retrieveOnlineFellowsBySchool.bind(this);
     this.loaderOpen = this.loaderOpen.bind(this);
     this.loaderClose = this.loaderClose.bind(this);
     this.openFullscreen = this.openFullscreen.bind(this);
     this.editStudentProfile = this.editStudentProfile.bind(this);
     this.setAssessmentStatus = this.setAssessmentStatus.bind(this);
     this.getAssessmentReferences = this.getAssessmentReferences.bind(this);
     this.resultScreen = this.resultScreen.bind(this);
     this.resultClose = this.resultClose.bind(this);
     this.rotateScreen = this.rotateScreen.bind(this);
     this.checkScreen = this.checkScreen.bind(this);
     this.resetProgress = this.resetProgress.bind(this);
     this.confirmPortfolioSave = this.confirmPortfolioSave.bind(this);
     this.candidateProgressVerification = this.candidateProgressVerification.bind(this);
     this.validateAnalysisQuizResponse = this.validateAnalysisQuizResponse.bind(this);
     this.activateForwardArrow = this.activateForwardArrow.bind(this);
     this.booksScreen = this.booksScreen.bind(this);
     this.booksClose = this.booksClose.bind(this);
     this.retrieveBooksLibrary = this.retrieveBooksLibrary.bind(this);
     this.booksScreenStart = this.booksScreenStart.bind(this);
     this.topicListings = this.topicListings.bind(this);
     this.topicScreen = this.topicScreen.bind(this);
     this.topicClose = this.topicClose.bind(this);
     this.closeLibrary = this.closeLibrary.bind(this);
     this.libraryShut = this.libraryShut.bind(this);
     this.Imageupload = this.Imageupload.bind(this);
     this.saveBackgroundImage = this.saveBackgroundImage.bind(this);
     this.getBackgroundImage = this.getBackgroundImage.bind(this); 
     this.saveThemeChoice = this.saveThemeChoice.bind(this)
     this.setTheme1 = this.setTheme1.bind(this)
     this.setTheme2 = this.setTheme2.bind(this)
     this.setTheme3 = this.setTheme3.bind(this)
     this.setTheme4 = this.setTheme4.bind(this)


}


 
async componentDidMount(){

//get current width & height of container
     this.getBackgroundImage()
     await  this.canvasHeight();
     await  this.canvasWidth();
     await this.candidateProgressVerification();
     this.retreiveResourceIconPack()

     await  this.retrieveResourceDetails();
     await this.retrieveCandidateDetails();
    
    
     this.setAssessmentStatus();
     this.editStudentProfile();
     //this.libraryShut()
     //this.closeLibrary()
  
     this.Imageupload()
    
     //this.responsiveClass();
}


async showSettings(){ 
        $('.settings').fadeIn('fast')
}

async settingsClose(){ 
  $('.settings').fadeOut('fast')
}


async getBackgroundImage(){

  this.getThemeChoice()
  
  const retrieveBackgroundImage = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/getBackgroundImage',{ 
    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
   mode:"cors",
   credentials:"include"
  })
  .then(response => response.json())
  .then(response => {
  
      if(!response){
      return   this.setState({ 
          backgroundImage: this.state.backgroundImage
        })
      }

      if(response){
        return this.setState({ 
          backgroundImage: response.backgroundImage
        })
      }
})

}


async saveBackgroundImage(backgroundImageData){
  
  const data = { 
    backgroundImage:backgroundImageData
  }

  const submitBackgroundImage = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/submitBackgroundImage',{ 
    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
     body:JSON.stringify(data),
   mode:"cors",
   credentials:"include"
  })
  .then(response => response.json())
  .then(response => {
      if(!response){
        return false
      }

      if(response){
        return console.log(response)
      }
})

}

async saveThemeChoice(colorA,colorB,colorC){
  
  const data = { 
    themecolorA:colorA,
    themecolorB:colorB,
    themecolorC:colorC 
  }

  const submitThemeChoice = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/submitThemeChoice',{ 
    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
     body:JSON.stringify(data),
   mode:"cors",
   credentials:"include"
  })
  .then(response => response.json())
  .then(response => {
      if(!response){
        return false
      }

      if(response){
        return   this.setState({ 
          themecolorA: response.themecolorA,
          themecolorB: response.themecolorB,
          themecolorC: response.themecolorC
        })
        
        console.log(response)
      }
})

}


async getThemeChoice(){
  
  const retrieveThemeChoice = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/getThemeChoice',{ 
    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
   mode:"cors",
   credentials:"include"
  })
  .then(response => response.json())
  .then(response => {
      if(response){
      return   this.setState({ 
          themecolorA: response.themecolorA,
          themecolorB: response.themecolorB,
          themecolorC: response.themecolorC
        })
      }

      if(!response){
        return false
      }
})

}


async setTheme1(){ 
  this.saveThemeChoice(212, 175, 65)
  window.responsiveVoice.speak(`Going Mustard`)
}

async setTheme2(){ 
  this.saveThemeChoice(23, 144, 247) 
  window.responsiveVoice.speak(`Activating Cool Blue`)
}

async setTheme3(){ 
  this.saveThemeChoice(12, 34, 23)
  window.responsiveVoice.speak(`Turning on Nigerian Green`)
}

async setTheme4(){ 

  this.saveThemeChoice(250, 0, 150)
  window.responsiveVoice.speak(`Hello Princess!`)
}




async candidateProgressVerification(){
  const {match:{params}} = this.props
  const data = { 
    currentTopic:params.topicRef,
    currentResource: params.resourceRef    
  }
  
  const progressCheck = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/verifyCandidateProgress',{ 

    method:"POST",
    headers:{ 
      "content-type" : "application/json"
     },
     body:JSON.stringify(data),
   mode:"cors",
   credentials:"include"
  
  })
  .then(response => response.json())
  .then(response => {
      if(!response){
        return console.log(false)
      }

      if(response){
        console.log(response)
console.log(`/lab/${response.currentTopic}/lessons/${response.currentResource}`)

window.location.href=`/lab/${response.currentTopic}/lessons/${response.currentResource}`
      }

})

}




canvasHeight() { 
    let availableSpaceHeight = document.getElementById('explanation').offsetHeight
     let  spaceHeight = availableSpaceHeight; 
       
    this.setState({
      height: spaceHeight.valueOf()
     }) 
}


canvasWidth() { 
     let availableSpaceWidth = document.getElementById('explanation').offsetWidth
     let spaceWidth = availableSpaceWidth 
    this.setState({
      width: spaceWidth.valueOf()
     }) 
  }


async shouldComponentUpdate(prevProps){

}

async componentDidUpdate(prevProps){

  
   
  
    const {match:{params}} = this.props
    //console.log(prevProps.match.params.resourceRef)
    //console.log(params.resourceRef)
    if (prevProps.match.params.resourceRef != params.resourceRef){   
        await this.retrieveResourceDetails()     
    }


    if (prevProps.match.params.topicRef != params.topicRef){          
        await this.retreiveResourceIconPack()
    }

  
         //console.log(this.state.taskComplete)
         //console.log(this.state.userAnswer)

          console.log(this.state.width)
         console.log(this.state.height)
         console.log(this.state.candidateSkin)

        

}

async componenDidRecieveProps(prevProps){


}

async openFullscreen() {
  if (document.requestFullscreen) {
    document.requestFullscreen();
  } else if (document.mozRequestFullScreen) { /* Firefox */
    document.mozRequestFullScreen();
  } else if (document.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    document.webkitRequestFullscreen();
  } else if (document.msRequestFullscreen) { /* IE/Edge */
    document.msRequestFullscreen();
  }
}

    
async Imageupload(e) {

  await  $.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
  // Initiate upload

var myWidget = window.cloudinary.createUploadWidget({
cloudName: 'www-code4teen-tech', 
uploadPreset: 'csizcotf'}, (error, result) => { 
if (!error && result && result.event === "success") { 
  console.log('Done! Here is the image info: ', result.info.secure_url); 
  //alert(result.info.secure_url)
  this.setState({
         backgroundImage:result.info.secure_url
       })

       this.saveBackgroundImage(result.info.secure_url)
  
    //this.props.taskStatus(true)
}
}
)

document.getElementById("upload_widget").addEventListener("click", function(){
myWidget.open();
}, false);


}


async checkScreen() {
console.log(window.screen.width)
  if(window.screen.width < 800 ){ 
    $("#labs").css({"display": "none"})
    $("#horizontal-screen").css({"display": "inline"})
  }else{

    $("#labs").css({"display": "inline"})
    $("#horizontal-screen").css({"display": "none"})

  }
}

async rotateScreen(){ 
  window.responsiveVoice.speak(`${this.state.candidateFirstName} , you need to rotate your screen, thank you!`) 
}

async booksScreen(){
let active
let menu
let profile
let explore 
let result
let books

active = document.getElementById('active-screen');
menu =  document.getElementById('menu-screen');
profile = document.getElementById('fellows-screen');
explore = document.getElementById('explore-screen');
result = document.getElementById('result-screen');
books = document.getElementById('book-screen');

$(active).fadeOut('fast',() => {
    $(books).fadeIn('fast', () => {
      $(profile).fadeOut('fast', () => {
        $(explore).fadeOut('fast', () => { 
          $(result).fadeOut('fast', () => { 
                $(menu).fadeOut('fast')
          })
        })
      })
    })
})

}

async booksClose(){

  let active
  let books
  let topics

  topics = document.getElementById('topic-screen');
  
  active = document.getElementById('active-screen');
  books =  document.getElementById('book-screen');
  
  $(topics).fadeOut('fast', () => {
    $(books).fadeOut('fast', () => {
      $(active).fadeIn('fast')   
    })
  })
  
  }


async menuScreen(){
  let active
  let menu
  let profile
  let explore 
  let result
  let books
  
  active = document.getElementById('active-screen');
  menu =  document.getElementById('menu-screen');
  profile = document.getElementById('fellows-screen');
  explore = document.getElementById('explore-screen');
  result = document.getElementById('result-screen');
  books =  document.getElementById('book-screen');
  

  $(active).fadeOut('fast',() => {
      $(menu).fadeIn('fast', () => {
        $(profile).fadeOut('fast', () => {
          $(explore).fadeOut('fast', () => { 
            $(result).fadeOut('fast', () => { 
              $(books).fadeOut('fast')
            })
          })
        })
      })
  })
  
  }

async menuClose(){

let active
let menu

active = document.getElementById('active-screen');
menu =  document.getElementById('menu-screen');

$(menu).fadeOut('fast', () => {
    $(active).fadeIn('fast')
})

}


async profileScreen(){ 
  console.log('i ran')
let active
let profile
let menu
let explore
let result
let books

active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');
menu =  document.getElementById('menu-screen');
explore = document.getElementById('explore-screen');
result = document.getElementById('result-screen');
books =  document.getElementById('book-screen');



$(active).fadeOut('fast',() => {
    $(profile).fadeIn('fast', () => { 
       $(menu).fadeOut('fast', () => { 
        $(explore).fadeOut('fast', () => { 
          $(result).fadeOut('fast', () => { 
                $(books).fadeOut('fast')
              })
           })
       })
    })
})

}

async profileClose(){

let active
let profile


active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');

$(profile).fadeOut('fast',() => {
    $(active).fadeIn('fast')
})

}



async resultClose(){

  let explore
  let result
  
  
  explore = document.getElementById('explore-screen');
  result =  document.getElementById('result-screen');
  
  $(result).fadeOut('fast',() => {
      $(explore).fadeIn('fast')
  })
  
  }


async exploreScreen(){ 
console.log('i ran')
let active
let profile
let menu
let explore
let result
let books


await this.retrieveCandidateDetails()
await this.setAssessmentStatus()
await this.getBackgroundImage()


explore = document.getElementById('explore-screen')
active = document.getElementById('active-screen');
profile =  document.getElementById('fellows-screen');
menu =  document.getElementById('menu-screen');
result = document.getElementById('result-screen');
books =  document.getElementById('book-screen');

$(active).fadeOut('fast',() => {
    $(explore).fadeIn('fast', () => { 
       $(menu).fadeOut('fast', () => { 
         $(profile).fadeOut('fast', () => { 
          $(result).fadeOut('fast', () => { 
            $(books).fadeOut('fast')
          })
         })
       })
    })
})

}





async retrieveCandidateScore(assessmentRefName){ 

 const {match:{params}} = this.props
  const data = { 
    assessmentRef:assessmentRefName,
    topicRef: params.topicRef     
  }
 
  const skinUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/getCandidateResults', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

   .then(response => response.json())
   .then(response => {
     console.log(response)
    this.setState({ 
        assessmentGrade: response.assessmentGrade,
        totalTopicGrade: response.totalTopicGrade,
        currentTopicGrade: response.currentTopicGrade,
        newAssessmentRef: response.newAssessmentRef
    })
   })
   console.log(this.state.assessmentGrade)

   console.log(this.state.totalTopicGrade)

   console.log(this.state.newAssessmentRef)
   


}



async resultScreen(event){ 
  console.log('i ran')
let active
let profile
let menu
let explore
let result 


let assessmentReference = event.target.id


 
  
  explore = document.getElementById('explore-screen')
  active = document.getElementById('active-screen');
  profile =  document.getElementById('fellows-screen');
  menu =  document.getElementById('menu-screen');
  result =  document.getElementById('result-screen');
  
  $(explore).fadeOut('fast',() => {
      $(result).fadeIn('fast', () => { 
       this.retrieveCandidateScore(assessmentReference)
  })
}) 


}

  
  async exploreClose(){
  
  let active
  let explore
  
  active = document.getElementById('active-screen');
  explore =  document.getElementById('explore-screen');
  
  $(explore).fadeOut('fast',() => {
      $(active).fadeIn('fast')
  })



}


async resetProgress(){

  
    const resetPlatform = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/resetProgress',{ 
    method:"POST",
    headers:{
       'content-type': 'application-JSON'
    }, 
    mode:'cors',
    credentials:'include'
    })

    .then(response => response.json())
    .then(response => {
      console.log(response)

      this.setState({
        resetModal: true,
          resetTopicName : response.topicName,
          resetVisual : response.topicVisual,
          resetTopicRef : response.topicRef,
          resetResourceRef: response.resourceRef   
      })
    })
}


async retrieveBooksLibrary(){ 
     
  const retrieveBooks = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/books',{ 
    method:"GET",
    headers:{
       'content-type': 'application-JSON'
    }, 
    mode:'cors',
    credentials:'include'
    })

    .then(response => response.json())
    .then(response => { 
      this.setState({ 
         booksPack: response
      })
     
    })

}

async topicListings(id){
  
  const data = { 
    bookNameReference:id,
  }
 
  const skinUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/topics/bookTopics', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

   .then(response => response.json())
   .then(response => {
     console.log(response)
     this.setState({
         reviewBookName : response.book[0].baseBookDetails.bookName,
         reviewBookCover: response.book[0].baseBookDetails.bookCover,
         bookTopics: response.topic
     })
   })
}

async closeLibrary(){ 

  $('.libraryClose').click(function(){ 
    let books
    let topics
    let active
    books =  document.getElementById('book-screen');
    topics = document.getElementById('topic-screen');
    active = document.getElementById('active-screen');
  
    $(topics).fadeOut('fast',() => {
      $(books).fadeOut('fast', () => { 

        $(active).fadeIn('fast')
    })
   }) 

  })

}




async libraryShut(){ 

  $('.libraryShut').click(function(){ 


    this.setState({
      notActiveModal: true
    })

  }.bind(this))

}

async topicScreen(event){ 
  console.log('i ran')
  let bookRef = event.target.id
let active
let profile
let menu
let explore
let result 
let books
let topics
   
  explore = document.getElementById('explore-screen')
  active = document.getElementById('active-screen');
  profile =  document.getElementById('fellows-screen');
  menu =  document.getElementById('menu-screen');
  books =  document.getElementById('book-screen');
  topics = document.getElementById('topic-screen');
  
  $(books).fadeOut('fast',() => {
      $(topics).fadeIn('fast', async () => { 
        await  this.topicListings(bookRef)
        this.closeLibrary()
    
  })
}) 
}

async topicClose(){
  let books
  let topics
  books =  document.getElementById('book-screen');
  topics = document.getElementById('topic-screen');

  $(topics).fadeOut('fast',() => {
    $(books).fadeIn('fast', () => { 
  })
 }) 
}

async progressUpdate(){ 

 console.log('progress ran')
   const {match :{params}} = this.props;

   console.log(params.resourceRef)
   console.log(params.topicRef)

   const responseReceived = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/progress-update/${params.topicRef}/${params.resourceRef}/`, {
     method: "POST",
     headers:{
      "content-type" : "application/JSON"
     },
     mode: "cors",
     credentials:"include"
    })

   const  response = await responseReceived.json()

}


//  async timeUpdate (){
  //const currentTime = moment().format('h:mm:ss a')
  //const timeDisplayContainer = document.getElementById('currentTime')
  //timeDisplayContainer.innerHTML= currentTime
   //} 


taskStatusUpdate(status){
    console.log(status)
    this.setState({ 
      taskComplete:status
    })
}

quizAnswerUpdate(answer){
    console.log(answer)
    this.setState({ 
       quizAnswer:answer
    })
}

quizResponseUpdate(answer){
 console.log('on change ran')
  this.setState({
    userAnswer: answer
  })
  console.log(this.state.userAnswer)
}

htmlStateUpdate(data){
    console.log(data)
    this.setState({ 
       codeData: data 
    });
}

byteStateUpdate(taskByteValue){
  console.log(taskByteValue)
   this.setState({ 
       taskByteValue: taskByteValue
  })
}

async activateForwardArrow(){
  this.setState({taskComplete: true })
}

async confirmPortfolioSave(status){
  console.log(status)

  if(status === true )  { 
    this.setState({taskComplete: status },  ToastsStore.success(`Great ${this.state.candidateFirstName}, use the green arrow to continue. You can now load your project in your portfolio`, 
      2000))
   window.responsiveVoice.speak(`Great ${this.state.candidateFirstName}, use the green arrow to continue. You can now load your project in your portfolio`) 
      this.candidateByteIncrease();
  }

  if(status === false) {

    this.setState({taskComplete: status},  ToastsStore.error(`${this.state.candidateFirstName} No, No. make sure your project name filled and image uploaded`, 5000)) 
  
  window.responsiveVoice.speak(`${this.state.candidateFirstName} No, No. make sure your project name is filled and your image is uploaded`) 
    }
}




async switchSkin(){ 
    console.log(' i ran ')  

    const recieveSkinPack = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins/',{ 
    method:"GET",
    headers:{
       'content-type': 'application-JSON'
    }, 
    mode:'cors',
    credentials:'include'
    })

    .then(response => response.json())
    .then(response => { 
      this.setState({ 
         skinPack: response
      })
      console.log(this.state.skinPack)
    })

}

async skinUpdate(event){

 const ref = event.target.id

 console.log(ref)
  const data = { skinRef: ref }
  const skinUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins/retrieveSkinLink/', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

   .then(response => response.json())
   .then(response => {
    this.setState({ 
        candidateSkin: response[0].skinLink
    })
   })

}

async skinMenu() {

 await this.menuScreen()
 await this.switchSkin()

}


async candidateByteIncrease(){

  console.log(this.state.taskByteValue)
  const data = {earnedByteValue: this.state.taskByteValue}
  const byteUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/response-api/v1/updateByteCount/increase/', { 
    method:"POST",
    headers:{
      "content-type":"application/JSON"
    },
    body:JSON.stringify(data),
    mode:"cors",
    credentials:'include' 
  })

  if(!byteUpdate){
   return console.log('server did not respond with byte')
  }

  const byteUpdateResponse = byteUpdate.json()
  console.log(byteUpdateResponse)

}


async retrieveOnlineFellowsBySchool(){ 

     console.log('get candidates ran')
   const retrievedOnlineFellows = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/get-online-candidates/',{ 
   method:"POST",
   headers:{
    "content-type" : "application-JSON"
   },
   mode:"cors",
   credentials:"include"
   })

   .then(response => response.json())
   .then(response => { 
      this.setState({ 
          onlineCandidates : response
      })
   })

} 


async profileMenu(){

 console.log('profile ran')
  await this.profileScreen()
  await this.retrieveOnlineFellowsBySchool()

}

async booksScreenStart(){
   window.responsiveVoice.speak(`What textbook would you like to switch to ?`)
  console.log('profile ran')
   await this.booksScreen()
   await this.retrieveBooksLibrary()
 
 }



async getAssessmentReferences() {

  const classAssessmentFilterParameters = { 
       classYear: this.state.candidateClass,
       schoolid: this.state.candidateSchoolid
  }
    
    console.log(classAssessmentFilterParameters)


const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/skins/assessmentRef',{ 

method:"POST",
headers:{ 
"content-type" : "application/json"
},
body: JSON.stringify(classAssessmentFilterParameters),
mode:"cors",
credentials:"include"

})
  .then(response => response.json())
  .then(response => { 
      console.log(response)
      this.setState({  
       currentAssessmentTaskRef: response[0].taskRef,
       currentAssessmentTopicRef: response[0].topicRef,
       currentAssessmentRef: response[0].assessmentRef
      })
  })
}


async setAssessmentStatus() { 
  console.log('ranning1')
  console.log(this.state.candidateAssessmentStatus)

  if(!this.state.candidateAssessmentStatus){

         $("#taskButton").addClass('disabled taskButton')

         
       
  }

  if(this.state.candidateAssessmentStatus){ 
console.log('ranning')
    await this.getAssessmentReferences()
    
     this.setState({ 
        assessmentLink: `/lab/${this.state.currentAssessmentTopicRef}/assessments/${this.state.currentAssessmentTaskRef}/category/${this.state.currentAssessmentRef}`
     }, ()=>{ 

         if($("#taskButton").hasClass('disabled taskButton')){
          $("#taskButton").removeClass('disabled taskButton')
         }
     })
       
  }

}

async editStudentProfile(){ 
$('#imageUpload').click(async function(e){ 

    console.log('uploadRan')

  e.preventDefault()
  await  $.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
  // Initiate upload

var myWidget = window.cloudinary.createUploadWidget({
cloudName: 'www-code4teen-tech', 
uploadPreset: 'csizcotf'}, async (error, result) => { 
if (!error && result && result.event === "success") { 
  console.log('Done! Here is the image info: ', result.info.secure_url); 

       //data sent in request body 
       let avatar = {
        avatar: result.info.secure_url
          }

      

        let updateAvatar = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/updateAvatar',{ 
          method: "POST",
          body: JSON.stringify(avatar),
          headers:{
            'Content-Type':'application/JSON',
            'Accept': 'application/JSON'
          },
          mode:'cors',
          credentials:'include'  
        })
      
        .then(response => response.json())
        .then(response => { 
        
          this.setState({ 
            candidateAvatar : response.avatar[0]
          })
        })
}
}
)

myWidget.open();


  }.bind(this))
}


async candidateByteDecrease(){


  this.setState({

    topicStatus:true,

  })

console.log("i ran")
const data = {ref: this.state.prevResourceRef}

const byteUpdate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/response-api/v1/updateByteCount/decrease/', { 
  method:"POST",
  headers:{
    "content-type":"application/JSON" 
  },
  body:JSON.stringify(data),
  mode:"cors",
  credentials:'include'
  })

if(!byteUpdate){
  return console.log('server did not respond with byte')
}

const byteUpdateResponse = byteUpdate.json()
console.log(byteUpdateResponse)
}


async saveEditorResponse(){

  const {match :{params}} = this.props;

  const data = {
    response:this.state.codeData,
    ref: params.resourceRef
   }

  const saveResponse = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/response-api/v1/saveEditorResponse/',{ 
    method: "POST",
    body: JSON.stringify(data),
    headers:{
      'Content-Type':'application/JSON',
      'Accept': 'application/JSON'
    },
    mode:'cors',
    credentials:'include'  
  })

  .then(response => response.json())
  .then(response => console.log('saved editor response'))
}


 //editor validation with button
async validateResponse(e) { 

  
  const { match: { params } } = this.props;
      e.preventDefault();
      console.log('button Click')      
      console.log(this.state.codeData)
          
         const data = {html:this.state.codeData}
         console.log(data)
          const validationResponse = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/evaluation-engine/validation-api/v1/${params.resourceRef}/`, {
            method:"POST",
            body:JSON.stringify(data),
            headers:{
               'Content-Type':'application/JSON',
               'Accept': 'application/json'
             },   
            mode: 'cors',
            credentials: 'include'
          })
          .then(response => response.json())
          .then(response => { console.log(response)
              if(response === true )  { 
                this.saveEditorResponse()
                this.setState({taskComplete: true },  ToastsStore.success(`Great Job ${this.state.candidateFirstName}, use the green arrow to continue`, 
                  2000))
               window.responsiveVoice.speak(`Great Job ${this.state.candidateFirstName}, use the green arrow to continue`) 
                  this.candidateByteIncrease();
              }

              if(response === false) {

                this.setState({taskComplete: false},  ToastsStore.error(`${this.state.candidateFirstName} No, No. Check your code`, 5000)) 
              
              window.responsiveVoice.speak(`${this.state.candidateFirstName} No, No. Check your code`) 
                }
           })          
  }

//editor validation from event driven call
  async eventValidateResponse() { 

    this.saveEditorResponse()
    const { match: { params } } = this.props;
       
        console.log('button Click')      
        console.log(this.state.codeData)
            
           const data = {html:this.state.codeData}
           console.log(data)
            const validationResponse = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/evaluation-engine/validation-api/v1/${params.resourceRef}/`, {
              method:"POST",
              body:JSON.stringify(data),
              headers:{
                 'Content-Type':'application/JSON',
                 'Accept': 'application/json'
               },   
              mode: 'cors',
              credentials: 'include'
            })
            .then(response => response.json())
            .then(response => { console.log(response)
                if(response === true )  { 
                  this.setState({taskComplete: true },  ToastsStore.success(`Great Job ${this.state.candidateFirstName}, use the green arrow to continue`, 
                    2000))
                 window.responsiveVoice.speak(`Great Job ${this.state.candidateFirstName}, use the green arrow to continue`) 
                    this.candidateByteIncrease();
                }
  
                if(response === false) {
  
                  this.setState({taskComplete: false},  ToastsStore.error(`${this.state.candidateFirstName} No, No. Check your code`, 5000)) 
                
                window.responsiveVoice.speak(`${this.state.candidateFirstName} No, No. Check your code`) 
                  }
             })          
    }

async validateQuizResponse(e){ 

  e.preventDefault();
   console.log('button click')

  console.log(this.state.userAnswer)
  console.log(this.state.quizAnswer)
 if(this.state.userAnswer == this.state.quizAnswer){
        this.setState({
            taskComplete:true
        }, ToastsStore.success(`Thats the right answer ${this.state.candidateFirstName}! use the green arrow to continue`,
       2000))

 window.responsiveVoice.speak(`That's the right answer ${this.state.candidateFirstName}! use the green arrow to continue`) 
      this.candidateByteIncrease();

 }else{
        this.setState({
            taskComplete:false
        }, ToastsStore.error(`That's incorrect ${this.state.candidateFirstName}. Check your answer`, 3000))

        window.responsiveVoice.speak(`That's incorrect ${this.state.candidateFirstName}. Check your answer`) 
 


  }  
}



async validateAnalysisQuizResponse(){ 

  
   console.log('analysis quiz')

  console.log(this.state.userAnswer)
  console.log(this.state.quizAnswer)
 if(this.state.userAnswer == this.state.quizAnswer){
        this.setState({
            taskComplete:true
        }, ToastsStore.success(`Thats the right answer ${this.state.candidateFirstName}! use the green arrow to continue`,
       2000))

 window.responsiveVoice.speak(`That's the right answer ${this.state.candidateFirstName}! use the green arrow to continue`) 
      this.candidateByteIncrease();

 }else{
        this.setState({
            taskComplete:false
        }, ToastsStore.error(`That's incorrect ${this.state.candidateFirstName}. Check your answer`, 3000))

        window.responsiveVoice.speak(`That's incorrect ${this.state.candidateFirstName}. Check your answer`) 
 


  }  
}



async retrieveCandidateDetails(){ 


    const candidate = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/candidate/',{ 
      method:"POST",
      headers:{ 
        "content-type" : "application/json"
       },
     mode:"cors",
     credentials:"include"
    })
    .then(response => response.json())
    .then(response => { 
       console.log(response)
       if(!response){
        window.location.href = "/"
      }
      this.setState({
      candidateFirstName:response.candidateFirstname,
      candidateFullname : response.candidateFirstname + " " + response.lastname,
      candidateSkin:response.skin,
      candidateAvatar: response.avatar[0],
      candidateClass : response.class,
      candidateTopicName: response.topicName,
      candidateTopicDescription: response.topicDescription,
      candidateTopicVisual: response.topicVisual,
      candidateAssessmentStatus : response.assessmentStatus,
      candidateAge : response.age,
      candidateSchool: response.school,
      candidateSchoolid : response.schoolid,
      schoollogo: response.logo,
      assessmentRef:response.assessmentReference
     })
    }

    )

}


async retrieveResourceDetails(){ 

       const {match:{ params }} = this.props

       const resourceRef = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${params.resourceRef}/forward-switch-resource/${params.topicRef}/`, { 
         method:"POST",
         mode:"cors", 
         headers:{ 
            "content-type" : "application-JSON" 
         },
         credentials:"include"
       })
   const resourceRefData = await resourceRef.json()
   
    console.log(resourceRefData)

    if(!resourceRefData){ 

      if(!resourceRefData.topicStatus.status){

         this.setState({ 
       resourceRef : resourceRefData.nextRef.ref,
       prevResourceRef: resourceRefData.prevRef.ref,
       topicRef: resourceRefData.nextTopic.ref,
       prevTopicRef: resourceRefData.prevTopic.ref,
       topicStatus: resourceRefData.topicStatus.status
            })
      return false
    }

  }

  if(resourceRefData){

    if(resourceRefData.topicStatus.status){

      this.setState({ 
      resourceRef : resourceRefData.nextRef.ref,
      prevResourceRef: resourceRefData.prevRef.ref,
      topicRef: resourceRefData.nextTopic.ref,
      prevTopicRef: resourceRefData.prevTopic.ref,
      topicStatus: resourceRefData.topicStatus.status

    })
     return false
   }

  }

  this.setState({ 
    topicStatus: false
  })

   console.log(resourceRefData.topicStatus)
   console.log(this.state.topicStatus)

 }




 async retreiveResourceIconPack(){ 

   console.log("fired")
    const {match:{params}} = this.props

    const iconPack = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${params.topicRef}/retrieveIconPack/`, { 
       method:"POST",
       headers:{
        "Content-Type" : "application/json"
       },
       mode:"cors",
       credentials:"include"     
    })   

    const  recievedIconPack = await iconPack.json()

    console.log(recievedIconPack)

    this.setState({ 
     iconPack : recievedIconPack
    })

 }


loaderOpen(){

  document.getElementById('load-screen').style.display = "block"
  document.getElementById('active-screen').style.visibility="hidden"

} 


loaderClose(){

  document.getElementById('load-screen').style.display = "none"
   document.getElementById('active-screen').style.visibility="visible"


} 

render(){ 


 let  newLabProgress = `/lab/${this.state.resetTopicRef}/lessons/${this.state.resetResourceRef}`
 
  setInterval(this.timeUpdate, 1000)
 


 //handle null and active button display with boolean state
    const {match: { params } } = this.props
    console.log(params.resourceRef)
    console.log(params.topicRef)
    let backwardSwitch 
    let forwardSwitch


          if(this.state.prevResourceRef === false){ 
            backwardSwitch = <img src={backwardSwitchButtonDisabled} width="40"/>
          }else{
         backwardSwitch = < BackwardSwitchButton prevTopicRef={this.state.prevTopicRef}  prevResourceRef={this.state.prevResourceRef} byteDecrease={this.candidateByteDecrease}/>
           }
         if (this.state.taskComplete === false || this.state.topicStatus === false){
           forwardSwitch = <img src={switchButtonDisabled} width="40"/> 
            }else{
           forwardSwitch = <SwitchButton topicRef={this.state.topicRef} resourceRef={this.state.resourceRef} currentProgress={this.progressUpdate}  />
            }

          
      let iconPackDetails
      let skinPackDetails
      let bookPackDetails
      let topicPackDetails
      let assessmentRefDetails
      let newRef 
      let onlineFellows

      if(this.state.assessmentRef.length){
        console.log(this.state.assessmentRef)

        assessmentRefDetails = this.state.assessmentRef.map( assessmentDetails => {
          if(!assessmentDetails.result){
           return <div className="row">
            <div className="col-6"> 
                  <h6 className="body-font white-text"> {assessmentDetails.topic} </h6>
            </div>
  
            <div className="col-6 body-font"> 
                   <button className="btn btn-outline-success disabled taskButton" id={assessmentDetails.ref}> See results </button>
                   </div>
  
          </div>
          }

          if(assessmentDetails.result){
             console.log('built')
            return <div className="row">
             <div className="col-6"> 
                   <h6 className="body-font white-text"> {assessmentDetails.topic} </h6>
             </div>
   
             <div className="col-6 body-font"> 
                    <button className="btn btn-outline-success results" onClick={this.resultScreen} id={assessmentDetails.ref}> See results </button>
                    </div>
   
           </div>
           }
   
        }
         
                
          
        
    

        )
      }

      if(this.state.iconPack.length){ 
        console.log(this.state.iconPack)
             iconPackDetails = this.state.iconPack.map(iconDetails => 

          <li className="standard-padding-tb text-center"> 
             <figure> 
             <img className="img-fluid" src={iconDetails.resourceIcon} width="80px"/>
             <figcaption className='header-font white-text mobile-text-size'>
             {iconDetails.ref}
             </figcaption> 
             </figure> 
             </li>
       )
         
      }

           if(this.state.skinPack.length){ 
     
          skinPackDetails = this.state.skinPack.map(skinDetails => 
          
           
          <div className="col-md-4 text-center"> 
             <figure> 
             <img src={skinDetails.skinLink} onClick={this.skinUpdate} value={skinDetails.ref} id={skinDetails.ref} width="250px"/>
             <figcaption className='header-font white-text'>
             {skinDetails.ref}
             </figcaption> 
             </figure> 
             </div>
       )
         
      }

      if(this.state.booksPack.length){ 
       console.log(this.state.booksPack)
        bookPackDetails = this.state.booksPack.map(bookDetails => 
        
         
        <div className="col-md-4 text-center"> 
           <figure> 
           <img src={bookDetails.bookCover} onClick={this.topicScreen} value="" id={bookDetails.bookNameReference} width="250px"/>
           <figcaption className='header-font white-text'>
           {bookDetails.bookName}
           </figcaption> 
           </figure> 
           </div>
     )
       
    }

    if(this.state.bookTopics.length){ 
     console.log(this.state.bookTopics)

    
      topicPackDetails = this.state.bookTopics.map(topicDetails => {
      
        if(topicDetails.Status === true){
          
       return   <div className="col-md-4 text-center"> 
          <figure  onClick={()=>  window.responsiveVoice.speak(`Got it! Taking you to ${topicDetails.topicName}`)}> 
          <img src={topicDetails.topicImage} value="" id="" width="250px"/>
          <figcaption className='header-font white-text '>
           <Link className="btn btn-success chapterSelection libraryClose"  to={`/lab/${topicDetails.topicRef}/lessons/${topicDetails.resourceRef}`}>
          {topicDetails.topicName}
          </Link>
          </figcaption> 
          </figure> 
          </div>
        } 

        if(topicDetails.Status === false){
          
      return    <div className="col-md-4 text-center"> 
           <figure onClick={()=>  window.responsiveVoice.speak(`${this.state.candidateFirstName} .... ${topicDetails.topicName} has not being activated by your educator`)}> 
          <img src={topicDetails.topicImage} value="" id="" width="250px"/>
          <figcaption className='header-font white-text '>
           <Link className="btn btn-danger chapterSelection libraryShut disabled" to="#"  >
          {topicDetails.topicName}
          </Link>
          </figcaption> 
          </figure> 
          </div>
   
        } 

       
      
      })
   
   
   
  
     
  }

      if(this.state.onlineCandidates.length){ 

          onlineFellows = this.state.onlineCandidates.map(candidateDetails => 

            <div className="col-md-2 text-center"> 
             <figure> 
             <img src={candidateDetails.avatar} width="150px"/>
             <figcaption className='header-font white-text'>
             {candidateDetails.firstname}
             </figcaption> 
             </figure> 
             </div>

          )

      }

   
 return(
  <div >

  
  <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="horizontal-screen"> 

<div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

 <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background text-center" > 
         <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
        <img className="standard-margin-top-quiz" src={atclogo} width='200' onClick={this.rotateScreen}/>   
         <figcaption>
             <h4 className="header-font text-center white-text"> Tap me ! </h4>
         </figcaption>
        </figure>
  </div>
</div>

 </div>

      <div className="" id='labs'>

        <header> 
         <Nav menuScreen={this.skinMenu} profileMenu={this.profileMenu} exploreScreen={this.exploreScreen} bookSelection={this.booksScreenStart} currentTopic={params.topicRef}  currentResource={params.resourceRef} closeLibrary={this.booksClose}  />
        </header>
         <div className="nav-clear-space-screen" >
           <div id="full-width" className="skin-actual full-width">
                 <img src={this.state.candidateSkin} width="100%" height="100%"  />
           </div>



      

           <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off"  id="explore-screen"> 
           <div className="col-6 mx-auto settings"> 
           <div className="col-12 col-md-11 mx-auto"> 
           
                <div className="row text-center"> 
                 <div className="col-12"> 
                 <FontAwesomeIcon icon={faXmark} className="chapterSelection" onClick={this.settingsClose}> </FontAwesomeIcon>
                    <h3 className="accent-font white-text"> Set a background Image </h3>
                    <button id="upload_widget" className="btn btn-outline-success cloudinary-button" > Change your background picture </button>
            
                  </div>

                  <div className="col-12"> 
                
                    <h3 className="accent-font white-text"> Choose a theme color </h3>
                    <div className="row">
                      <div className="col-3 mx-auto themeHeight themeHeight2" onClick={this.setTheme1}>

                      </div>

                      <div className="col-3 mx-auto themeHeight themeHeight1" onClick={this.setTheme2}>
                        
                      </div>

                      <div className="col-3 mx-auto themeHeight themeHeight3" onClick={this.setTheme3}>
                        
                      </div>

                      <div className="col-3 mx-auto themeHeight themeHeight4" onClick={this.setTheme4}>
                        
                        </div>
                    </div>
            
                  </div>
                
                 
          
                  </div>

                </div>

             </div> 
                 
      
        <div className="row left-side-curve top-right-curve bottom-right-curve black-background" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5)), url(${this.state.backgroundImage}) center center`}} id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off " > 
               
          <div className="row standard-padding-top-1 standard-margin-top fixed-top">
          <div className="col-6 col-md-5 offset-md-1">
          <img id="" src={this.state.schoollogo} width="70"/>
           </div> 
           <div className="col-5">
             <h5 className="header-font text-right white-text"> Close Explore <span> <img src={forwardSwitchButton} onClick={this.exploreClose} width='35' /> </span> </h5>
           </div> 
           </div>
           <div className="row standard-padding-top-1">
           <div className="col-12 text-center">
                <img id="candidateImage" src={this.state.candidateAvatar} width="100"/>
             </div>

             <div className="col-12 text-center"> 
             <button className="btn btn-danger" id="imageUpload"> Change Photo </button>
             </div>

             <div className="col-12 col-md-4 mx-auto"> 
                <div className="row text-center"> 
                 <div className="col-12"> 
                    <h3 className="accent-font white-text"> {this.state.candidateFullname} </h3>
                  </div>
                  <div className="col-12 col-md-3"> 
                  
                    <FontAwesomeIcon icon={faUserGear} className="chapterSelection" onClick={this.showSettings}> </FontAwesomeIcon>
               
          
                  </div>
                  <div className="col-12 col-md-3"> 
                    <h6 className="body-font white-text"> Age:{this.state.candidateAge} </h6>
                  </div>

                  <div className="col-12 col-md-3"> 
              <h6 className="body-font white-text"> Grade:{this.state.candidateClass} </h6>
                  </div>

                  <div className="col-12 col-md-3"> 
                  <Link to={this.state.assessmentLink}>
              <button className="btn btn-outline-danger" id="taskButton"> Pending Task </button>
              </Link>
                  </div>

                </div>

      <hr className="white-border full-width" />

             </div> 




          </div> 
    <div className="row standard-margin-top">
             <div className="col-12 col-md-4 offset-md-1 text-center">
            <h6 className="accent-font white-text"> You are currently learning </h6>
            <img src={this.state.candidateTopicVisual} width="100%"/>
            <h4 className="header-font white-text"> {this.state.candidateTopicName} </h4>
            <h6 className="body-font white-text"> {this.state.candidateTopicDescription} </h6>
            <div className="col-12">
             <button className="btn btn-outline-danger resetProgress" onClick={this.resetProgress}> Reset Progress </button>
             </div>
             </div>
           
             <div className="col-12 offset-md-1 col-md-6"> 
          <h6 className="accent-font white-text"> Your assessments for {this.state.candidateTopicName} </h6>
             {assessmentRefDetails}



             </div>
           


               
             </div>

        </div>

        </div>
    
       </div>
            
       <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off"  id="result-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5)), url(${this.state.backgroundImage}) center center`}}  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> Here's how you performed {this.state.candidateFirstName} </h2>
           </div> 
           <div className="col-12 col-md-5 mx-auto text-center">
         <h4 className="body-font white-text"> {this.state.candidateTopicName} - {this.state.newAssessmentRef} </h4>
         </div>
         <div className="col-10 mx-auto">
         <img src={this.state.candidateTopicVisual} width="100%"/>
         </div>
       
         <div className="col-12 col-md-4 text-center">

           <h4 className="accent-font white-text"> Assessment Score</h4>
           <h1 className="body-font white-text">  
             {this.state.assessmentGrade}
                     
           </h1>

         </div>

         <div className="col-12 col-md-4 text-center">

           <h4 className="accent-font white-text"> Current Topic Score</h4>
           <h1 className="body-font white-text"> 
             {this.state.currentTopicGrade}
            </h1>

         </div>

         <div className="col-12 col-md-4 text-center">

            <h4 className="accent-font white-text"> Total Topic Score</h4>
            <h1 className="body-font white-text"> 
              {this.state.totalTopicGrade}
             </h1>

         </div>

           <div className="col-12">
             <h5 className="header-font text-right white-text">  <span> <img src={forwardSwitchButton} onClick={this.resultClose} width='35' /> </span> back to explore </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>



      <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="fellows-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5)), url(${this.state.backgroundImage}) center center`}}  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> See whose coding in your class </h2>
           </div> 
           {onlineFellows}
           <div className="col-12">
             <h5 className="header-font text-right white-text"> close skin menu <span> <img src={forwardSwitchButton} onClick={this.profileClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>

            <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="menu-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve"  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> Hello, Select a skin for your tablet </h2>
           </div> 
           {skinPackDetails}
           <div className="col-12">
             <h5 className="header-font text-right white-text"> save and close skin menu <span> <img src={forwardSwitchButton} onClick={this.menuClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>


       <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="book-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5)), url(${this.state.backgroundImage}) center center`}}  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-12 standard-padding-tb"> 
            <h2 className="white-text accent-font text-center"> Hello {this.state.candidateFirstName}, Select a book </h2>
           </div> 
           {bookPackDetails}
           <div className="col-12">
             <h5 className="header-font text-right white-text"> Close library <span> <img src={forwardSwitchButton} onClick={this.booksClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>

       <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="topic-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5)), url(${this.state.backgroundImage}) center center`}}  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 
               
          <div className="row">
           <div className="col-md-5 standard-padding-tb text-center"> 
            <h5 className="white-text accent-font text-center"> Book Summary </h5>
            <img src={this.state.reviewBookCover} width="80%" className="img-fluid" />
           </div> 
           <div className="col-md-7 standard-padding-tb"> 
           <h5 className="white-text accent-font text-center"> Pick a chapter </h5>
           <div className="row">
           {topicPackDetails}
           </div>
         
           </div> 
           
           <div className="col-12">
             <h5 className="header-font text-right white-text"> Back to library <span> <img src={forwardSwitchButton} onClick={this.topicClose} width='35' /> </span> </h5>
           </div> 

          </div> 
    

        </div>

        </div>
    
       </div>




                     <div className="container top-right-curve bottom-right-curve skin padding-off" id="sleep-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5)), url(${this.state.backgroundImage}) center center`}}  id="labBoxStyle"> 

        <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off black-background" > 

          <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
              <h6 className="body-font text-center white-text" id="currentTime">  </h6>
              <img className="standard-margin-top-quiz" src={atclogo} width='200' />
              <figcaption className="standard-margin-top-quiz">
                 <h5 className="header-font text-center white-text"> Wake Andrew Up <span> <img src={forwardSwitchButton} onClick={this.wakeScreen} width='35' /> </span> </h5>
              </figcaption> 
          </figure>
    

        </div>

        </div>
    
       </div>



       <div className="app-screen-padding-1 top-right-curve bottom-right-curve skin padding-off" id="load-screen"> 

      <div className="row left-side-curve top-right-curve bottom-right-curve" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 0.5)), url(${this.state.backgroundImage}) center center`}}  id="labBoxStyle"> 

       <div className="col-md-12 left-side-curve top-right-curve bottom-right-curve padding-off text-center" > 
               <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
              <img className="standard-margin-top-quiz" src={atclogo} width='200' />   
               <figcaption>
                   <h5 className="header-font text-center white-text"> One second please </h5>
               </figcaption>
              </figure>
        </div>
      </div>

       </div>


       


           <div className="app-screen-margin top-right-curve bottom-right-curve skin padding-off" id="active-screen"> 
        <div className="row left-side-curve top-right-curve bottom-right-curve" id="labBoxStyleClass"> 
        <div className="col-md-2 top-left-curve bottom-left-curve" style={{background:`linear-gradient(0deg, rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 1), rgba(${this.state.themecolorA}, ${this.state.themecolorB}, ${this.state.themecolorC}, 1)), url(${this.state.backgroundImage}) center center`}} id="sidebar-properties"> 
         <div className="row"> 
         <div className="col-12 green-background top-left-curve standard-padding-tb sub-menu-style"> 
                 <p className="white-text text-center margin-off body-font mobile-text-size"> We are learning </p> 
         </div>
          <div className="col-12 height-test-minus sub-menu-layout standard-padding-tb ">
           <ul className="padding-off"> 
            {iconPackDetails}
         </ul>
           
          </div>
          <div className="col-12 standard-padding-tb text-center green-background"> 

              {backwardSwitch}
              {forwardSwitch}  
          </div>
         </div>    
           <p className="accent-font white-text text-center mobile-text-size"> {this.state.candidateFirstName}'s booklet </p>     
        </div>

        <div className="col-md-10 padding-off white-background" id="explanation"> 

        {this.props.children}
         <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} timer={10000}/>
        <Route path={`/lab/:topicRef/lessons/:resourceRef`} render={ (props) => <TheWeb {...props} loaderClose={this.loaderClose} loaderOpen={this.loaderOpen} dynamicWidth={this.state.width} dynamicHeight={this.state.height} byteStateValue={this.byteStateUpdate} onHtmlChange={this.htmlStateUpdate} validateResponse={this.validateResponse} eventValidateResponse={this.eventValidateResponse} validateQuizResponse={this.validateQuizResponse} validateAnalysisQuizResponse={this.validateAnalysisQuizResponse} quizAnswerUpdate={this.quizAnswerUpdate} quizResponseUpdate={this.quizResponseUpdate} progressUpdate={this.progressUpdate} taskStatusUpdate={this.taskStatusUpdate} taskCompletionUpdate={this.state.taskComplete} confirmPortfolioSave={this.confirmPortfolioSave} activateForwardArrow={this.activateForwardArrow} candidateFirstName={this.state.candidateFirstName} candidateAvatar={this.state.candidateAvatar} /> } />
       



      <Modal className="white-background-trans" show={this.state.resetModal} animation={false}>
           <Modal.Header>
          <Modal.Title> Your book has been successfully reset </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padding-off">
          <div className="row">
            <div className="col-12">
             Great, you are now going back to chapter one of Andrew Teaches Coding- Term A 
             </div> 

             <div className="col-12">
               <figure>
             <img src={this.state.resetVisual} width="100%" />
               <figcaption> 
                    <h4 className="header-font"> {this.state.resetTopicName} </h4>
               </figcaption>
             </figure>
             </div> 

             <div className="col-12 text-center"> 

             <Link to={newLabProgress}>
                   <button onClick={()=> this.setState({resetModal:false}, ()=>this.exploreClose())} className="btn btn-success"> Continue </button>
             </Link> 
             </div>
             </div>
      

          </Modal.Body>
      </Modal>


      <Modal className="white-background-trans" show={this.state.notActiveModal} animation={false}>
           <Modal.Header>
          <Modal.Title> Navigation Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="padding-off">
          <div className="row">
            <div className="col-12">
             The selected topic has not been activated by your teacher
             </div> 

             <div className="col-12 text-center"> 

             
                   <button onClick={()=> this.setState({notActiveModal:false})} className="btn btn-success"> Close </button>
       
             </div>
             </div>
      

          </Modal.Body>
      </Modal>

    

        </div>

        </div>
    
      </div>
       </div>
     


         <div className="row" id="alert" style={{display: "none"}}>
            <div className="col-12 mt-5">
                <div className="alert alert-danger text-center" role="alert">
                  <h4 className="alert-heading alertMsg" id="alertTitle"></h4>
                  <img src="rotate.gif" alt="Rotate gif" className="img-fluid d-none" id="gif" />
                  <p id="alertBody"></p>
                </div>
            </div>
        </div>
       </div>
       </div>

     	)   
 }
}


export default lab;