import React, {Component} from 'react';
import{Link} from 'react-router-dom';

class progress extends Component { 
   constructor(props) { 
    super(props);

     this.state ={ 
        topics :[]
     }; 

     this.retrieveProjectList = this.retrieveProjectList.bind(this)
   }


   async componentDidMount(){ 
     
     await this.retrieveProjectList()
     console.log(this.state.topics)

   }


    async retrieveProjectList(){

    	const topicList = await fetch('https://atcbrain-7de683f08325.herokuapp.com/learning-engine/porfolio-api/v1/findAllProjects',{ 
          method: "GET",
          headers:{ 
            "Content-Type" : "application/json"
          },
          mode:"cors",
          credentials:"include"
    	})
    	.then(response => response.json())
    	.then(response => {console.log(response) 
            
               this.setState({
               	topics:response
               })
    	})

    }


   render() { 

      if(!this.state.topics.length){ 
          return null;
      }

   	  let projectList = this.state.topics.map(topic =>
         
          
          <div className="blue-background-lightcol-12 standard-margin-top col-md-4 IWH" id="navbarStyle"> 

          <div className="badge blue-background-2 white-text"> {topic.projectName} </div>
            <img src={topic.projectImage} width="100%"/>
       <Link to={`/portfolio/${topic.projectName}/preview/${topic._id}`}> 
          <button className="btn btn-success standard-margin-top standard-round-box-curve green-background white-text header-font tabs-btn-hover"> Open </button>
       </Link>

      <Link to={`/portfolio/${topic.projectName}/edit/${topic._id}`}> 
          <button className="btn btn-success standard-margin-top standard-round-box-curve green-background white-text header-font tabs-btn-hover"> Edit </button>
       </Link>

          </div>




         
            )

     return(
     <div>

<ul  className="nav nav-tabs portfolio-selection"  id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button className="nav-link active border-control accent-font grey-text white-background" id="home-tab" data-toggle="tab" data-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{this.props.candidateFirstName}'s Portfolio</button>
  </li>
  <li class="nav-item" role="presentation">
    <button className="nav-link border-control accent-font grey-text white-background" id="profile-tab" data-toggle="tab" data-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"> Project Collection </button>
  </li>
  <li class="nav-item" role="presentation">
    <button className="nav-link border-control accent-font grey-text white-background" id="contact-tab" data-toggle="tab" data-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"> Class Collection </button>
  </li>
</ul>

      
         <div className="container browserPadding">
         <div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
  <div className="row standard-margin-top">

<div className="col-12"> 
 <h3 className="accent-font"><span className="accent-font"></span> </h3> 
  <h6 className="body-font"> Click on any project to view your progress </h6> 
</div>

</div>

<div className="row blue-background-light">
{projectList}

 </div>
   
   </div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
  <div className="row standard-margin-top">

<div className="col-12"> 
 <h3 className="accent-font"><span className="accent-font"></span> </h3> 
  <h6 className="body-font"> Here's a look at projects you have collected </h6> 
</div>

</div>

<div className="row blue-background-light">
{projectList}

 </div>
   
   </div>
  <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
  <div className="row standard-margin-top">

<div className="col-12"> 
 <h3 className="accent-font"><span className="accent-font"></span> </h3> 
  <h6 className="body-font"> All projects shared by your classmates </h6> 
</div>

</div>

<div className="row blue-background-light">
{projectList}

 </div>
   
   </div>
</div>



         </div>
         </div>
     	)
     }
   }

export default progress